function Footer() {
  return (
    <footer>
      <div className="footer-content">
        <div className="footer-section">
          <h3>Fashion Store</h3>
          <p>Thời trang cao cấp cho mọi người</p>
          <div className="social-links">
            <a href="#">
              <i className="fab fa-facebook" />
            </a>
            <a href="#">
              <i className="fab fa-instagram" />
            </a>
            <a href="#">
              <i className="fab fa-twitter" />
            </a>
            <a href="#">
              <i className="fab fa-youtube" />
            </a>
          </div>
        </div>
        <div className="footer-section">
          <h3>Thông tin</h3>
          <ul>
            <li>
              <a href="#about">Về chúng tôi</a>
            </li>
            <li>
              <a href="#">Chính sách bảo mật</a>
            </li>
            <li>
              <a href="#">Điều khoản sử dụng</a>
            </li>
            <li>
              <a href="#">Chính sách đổi trả</a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Tài khoản</h3>
          <ul>
            <li>
              <a href="#">Tài khoản của tôi</a>
            </li>
            <li>
              <a href="#">Lịch sử đơn hàng</a>
            </li>
            <li>
              <a href="#">Danh sách yêu thích</a>
            </li>
            <li>
              <a href="#">Giỏ hàng</a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Thanh toán</h3>
          <div className="payment-methods">
            <i className="fab fa-cc-visa" />
            <i className="fab fa-cc-mastercard" />
            <i className="fab fa-cc-paypal" />
            <i className="fas fa-money-bill-wave" />
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 Fashion Store. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
