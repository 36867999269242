import { faSearch, faUser } from "@fortawesome/free-solid-svg-icons";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';

function HeaderNotNav() {
  return (
    <header>
      <nav className="navbar">
        <div className="logo">
          <h1>
            <Link to={"/"} href="index.html">Fashion Store</Link>
          </h1>
        </div>
      </nav>
    </header>
  );
}

export default HeaderNotNav;
