import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { formatPrice } from "../share";
import { useNavigate } from "react-router-dom";

function ProductCard({ product }) {
  let productRef = useRef(null);
  let followRef = useRef(null);
  const navigate = useNavigate();

  const handleClick = (e) => {
    const isFollowButtonClicked = followRef.current.contains(e.target);
    if (!isFollowButtonClicked && productRef.current.contains(e.target)) {
      navigate(`/products/${product.id}`);
    }
  };

  return (
    <div
      onClick={handleClick}
      ref={productRef}
      className="product-card"
    >
      <div className="product-image-container">
        <img
          src={product.thumbnail.path}
          alt={product.name}
          className="product-image"
        />
        <button className="wishlist-btn" ref={followRef}>
          <FontAwesomeIcon icon={faHeart} />
        </button>
      </div>
      <div className="product-info">
        <h3>{product.name}</h3>
        <p className="product-description">{product.sort_description}</p>
        <p className="product-price">
          {formatPrice(
            Math.min(...product.variants.map((product) => Number(product.price)))
          )}
        </p>
      </div>
    </div>
  );
}

export default ProductCard;
