import {
  faHeart,
  faSearch,
  faShoppingBag,
  faSignInAlt,
  faSignOutAlt,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import apiBaseUrl, { formatPrice, getHeader } from "../share";
import { useEffect, useState } from "react";
import { useCart } from "./CartContext";

function Header() {
  const { remember, header } = getHeader();
  const { cartItems, loading, status, logout, checkOut, loadingCheckOut } =
    useCart();
  const [show, setShow] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [countCart, setCountCart] = useState(0);
  const [sumBill, setSumBill] = useState(0);
  const navigation = useNavigate();
  let timeoutId, timeoutCartId;

  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
    setShow(true);
    setShowCart(false);
  };
  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setShow(false);
    }, 200);
  };

  const handleMouseEnterCart = () => {
    clearTimeout(timeoutCartId);
    setShowCart(true);
    setShow(false);
  };
  const handleMouseLeaveCart = () => {
    timeoutCartId = setTimeout(() => {
      setShowCart(false);
    }, 200);
  };

  let user = null;
  if (remember == "true") {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = JSON.parse(sessionStorage.getItem("user"));
  }

  useEffect(() => {
    let totalQuantity = 0;
    let totalBill = 0;
    if (cartItems.length > 0) {
      totalQuantity = cartItems.reduce((sum, item) => {
        return (
          sum +
          item.variants.reduce(
            (variantSum, variant) => variantSum + variant.quantity,
            0
          )
        );
      }, 0);
      totalBill = cartItems.reduce((sum, item) => {
        return (
          sum +
          item.variants.reduce(
            (variantSum, variant) =>
              variantSum +
              item.product.variants.find(
                (variant1) => variant1.size === variant.size
              ).price *
                variant.quantity,
            0
          )
        );
      }, 0);
    }
    setSumBill(totalBill);
    setCountCart(totalQuantity);
  }, [cartItems]);

  return (
    <>
      <header>
        <nav className="navbar">
          <div className="logo">
            <h1>
              <Link to="/">Fashion Store</Link>
            </h1>
          </div>
          <ul className="nav-links">
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
            <li>
              <a href="#trending">Xu hướng</a>
            </li>
            <li>
              <a href="#products">Sản phẩm</a>
            </li>
            <li>
              <a href="#about">Về chúng tôi</a>
            </li>
            <li>
              <a href="#contact">Liên hệ</a>
            </li>
          </ul>
          <div className="nav-icons">
            <div className="search-icon" title="Tìm kiếm">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <div
              className="cart-icon"
              title="Giỏ hàng"
              onMouseEnter={handleMouseEnterCart}
              onMouseLeave={handleMouseLeaveCart}
            >
              <FontAwesomeIcon icon={faShoppingCart} />
              <span className="cart-count">
                {countCart > 99 ? "99+" : countCart}
              </span>
            </div>
            <div
              className="user-icon"
              title="Tài khoản"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <FontAwesomeIcon icon={faUser} />
            </div>
          </div>
        </nav>
      </header>

      <div
        className={`user-menu ${show ? "block" : "hidden"}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="user-menu-content">
          {header ? (
            <>
              <div className="user-info">
                <p>Xin chào, {user?.name || "User"}</p>
              </div>
              <ul>
                <li>
                  <a href="#">
                    <FontAwesomeIcon icon={faUser} /> Tài khoản của tôi
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FontAwesomeIcon icon={faShoppingBag} /> Đơn hàng
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FontAwesomeIcon icon={faHeart} /> Yêu thích
                  </a>
                </li>
                <li>
                  <Link onClick={logout}>
                    <FontAwesomeIcon icon={faSignOutAlt} /> Đăng xuất
                  </Link>
                </li>
              </ul>
            </>
          ) : (
            <>
              <div className="auth-links">
                <Link to={"/auth"} className="cta-button">
                  <FontAwesomeIcon icon={faSignInAlt} /> Đăng nhập
                </Link>
                <p className="register-link">
                  Chưa có tài khoản?
                  <Link to={"/auth/register"}>Đăng ký ngay</Link>
                </p>
              </div>
            </>
          )}
        </div>
      </div>

      <div
        className={`cart-preview ${showCart ? "block" : "hidden"}`}
        onMouseEnter={handleMouseEnterCart}
        onMouseLeave={handleMouseLeaveCart}
      >
        <div className="cart-preview-header">
          <h3>Giỏ hàng</h3>
        </div>
        {loading ? (
          <div role="status" className="h-20 flex justify-center items-center">
            <svg
              aria-hidden="true"
              className="inline w-9 h-9 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        ) : cartItems && cartItems.length > 0 ? (
          <>
            {" "}
            <div className="cart-preview-items">
              {cartItems.map((item, index) => (
                <div className="cart-item items-stretch" key={index}>
                  <img src={item.product.thumbnail.path} alt="" />
                  <div className="cart-item-info flex-1">
                    <h4>{item.product.name}</h4>
                    <div className="cart-item-details">
                      {item.variants.map((item1, index) => {
                        return (
                          <div
                            className="flex items-center justify-between"
                            key={index}
                          >
                            <span className="cart-item-size">
                              Size: {item1.size.toUpperCase()}
                            </span>
                            <span className="cart-item-price">
                              {formatPrice(
                                item.product.variants.find(
                                  (variant) => variant.size === item1.size
                                ).price
                              )}{" "}
                              x {item1.quantity}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <button
                    // onclick="removeFromCart(${item.id}, '${item.size}')"
                    className="remove-item flex items-start"
                  >
                    <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                  </button>
                </div>
              ))}
            </div>
            <div className="cart-preview-footer">
              <div className="cart-total">
                Tổng cộng: {formatPrice(sumBill)}
              </div>
              <button
                className="cta-button checkout-btn"
                onClick={checkOut}
                disabled={loadingCheckOut}
              >
                {" "}
                {loadingCheckOut ? (
                  <div
                    role="status"
                    className="flex justify-center items-center"
                  >
                    <svg
                      aria-hidden="true"
                      className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                ) : (
                  "Thanh toán"
                )}
              </button>
            </div>
          </>
        ) : (
          <p className="empty-cart">Giỏ hàng trống</p>
        )}
      </div>
      {status && status.show ? (
        <div className={`notification ${status.status}`}>
          <ul>
            {status.message.map((value, index) => {
              return <li key={index}>{value}</li>;
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
export default Header;
