import React, { useState, useEffect, useRef } from "react";
import trend_1 from "../assets/img/trend-3.jpg";
import trend_2 from "../assets/img/trend-4.jpg";
import imageAbout from "../assets/img/about.jpg";
import apiBaseUrl, { formatPrice } from "../share";
import {
  faHeadset,
  faHeart,
  faShieldAlt,
  faShoppingCart,
  faTruck,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import ProductCard from "../components/ProductCard";

function Home() {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/product/list`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setProducts(data.body);
      } catch (error) {
        console.error("Failed to fetch products:", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div>
      {/* Hero Section */}
      <section className="hero" id="home">
        <div className="hero-content">
          <h1>Xu hướng thời trang mới nhất</h1>
          <p>Khám phá bộ sưu tập độc đáo và phong cách</p>
          <a href="#products" className="cta-button">
            Mua sắm ngay
          </a>
        </div>
      </section>

      {/* Features Section */}
      <section className="features">
        <div className="feature-item">
          <FontAwesomeIcon icon={faTruck} />
          <h3>Miễn phí vận chuyển</h3>
          <p>Cho đơn hàng trên 500K</p>
        </div>
        <div className="feature-item">
          <FontAwesomeIcon icon={faUndo} />
          <h3>Đổi trả miễn phí</h3>
          <p>Trong vòng 30 ngày</p>
        </div>
        <div className="feature-item">
          <FontAwesomeIcon icon={faHeadset} />
          <h3>Hỗ trợ 24/7</h3>
          <p>Tư vấn nhiệt tình</p>
        </div>
        <div className="feature-item">
          <FontAwesomeIcon icon={faShieldAlt} />
          <h3>Thanh toán an toàn</h3>
          <p>Bảo mật thông tin</p>
        </div>
      </section>

      {/* Trending Section */}
      <section className="trending" id="trending">
        <h2>Xu hướng mùa này</h2>
        <div className="trending-grid">
          <div className="trending-item">
            <img src={trend_1} alt="Trend 1" />
            <div className="trending-content">
              <h3>Bộ sưu tập mùa đông</h3>
              <p>Thoải mái và năng động</p>
              <a href="winter-collection.html" className="trend-link">
                Khám phá ngay
              </a>
            </div>
          </div>
          <div className="trending-item">
            <img src={trend_2} alt="Trend 2" />
            <div className="trending-content">
              <h3>Phong cách công sở</h3>
              <p>Lịch lãm và chuyên nghiệp</p>
              <a href="office-style.html" className="trend-link">
                Khám phá ngay
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Products Section */}
      <section className="products" id="products">
        <h2>Sản phẩm nổi bật</h2>
        <div className="product-grid">
          {products.map((item, index) => (
            <ProductCard product={item} key={index} />
          ))}
        </div>
      </section>

      <>
        {/* About Section */}
        <section className="about" id="about">
          <div className="about-container">
            <div className="about-content">
              <div className="about-text">
                <h2>Về Fashion Store</h2>
                <p className="about-description">
                  Fashion Store là thương hiệu thời trang hàng đầu, mang đến
                  những sản phẩm chất lượng cao với giá cả hợp lý. Chúng tôi cam
                  kết mang đến trải nghiệm mua sắm tuyệt vời nhất cho khách
                  hàng.
                </p>
                <div className="about-features">
                  <div className="feature">
                    <i className="fas fa-tshirt" />
                    <h3>Sản phẩm đa dạng</h3>
                    <p>
                      Nhiều mẫu mã, phong cách khác nhau phù hợp với mọi lứa
                      tuổi
                    </p>
                  </div>
                  <div className="feature">
                    <i className="fas fa-medal" />
                    <h3>Chất lượng cao cấp</h3>
                    <p>
                      Sử dụng các chất liệu cao cấp, đảm bảo độ bền và thoải mái
                    </p>
                  </div>
                  <div className="feature">
                    <i className="fas fa-tags" />
                    <h3>Giá cả hợp lý</h3>
                    <p>Mức giá phù hợp với nhiều đối tượng khách hàng</p>
                  </div>
                </div>
                <div className="about-stats">
                  <div className="stat-item">
                    <span className="stat-number">1000+</span>
                    <span className="stat-label">Sản phẩm</span>
                  </div>
                  <div className="stat-item">
                    <span className="stat-number">50K+</span>
                    <span className="stat-label">Khách hàng</span>
                  </div>
                  <div className="stat-item">
                    <span className="stat-number">30+</span>
                    <span className="stat-label">Chi nhánh</span>
                  </div>
                </div>
              </div>
              <div className="about-image">
                <img src={imageAbout} alt="Fashion Store" />
                <div className="about-experience">
                  <span className="years">10+</span>
                  <span className="text">Năm kinh nghiệm</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Newsletter Section */}
        <section className="newsletter">
          <div className="newsletter-content">
            <h2>Đăng ký nhận tin</h2>
            <p>Nhận thông tin về sản phẩm mới và ưu đãi đặc biệt</p>
            <form className="newsletter-form">
              <input type="email" placeholder="Nhập email của bạn" />
              <button type="submit" className="cta-button">
                Đăng ký
              </button>
            </form>
          </div>
        </section>
        {/* Contact Section */}
        <section className="contact" id="contact">
          <h2>Liên hệ với chúng tôi</h2>
          <div className="contact-content">
            <div className="contact-info">
              <div className="contact-item">
                <i className="fas fa-map-marker-alt" />
                <h3>Địa chỉ</h3>
                <p>123 Đường ABC, Quận XYZ</p>
              </div>
              <div className="contact-item">
                <i className="fas fa-phone" />
                <h3>Điện thoại</h3>
                <p>(084) 123-456-789</p>
              </div>
              <div className="contact-item">
                <i className="fas fa-envelope" />
                <h3>Email</h3>
                <p>contact@fashionstore.com</p>
              </div>
            </div>
            <form className="contact-form">
              <input type="text" placeholder="Họ và tên" />
              <input type="email" placeholder="Email" />
              <textarea placeholder="Nội dung" defaultValue={""} />
              <button type="submit" className="cta-button">
                Gửi tin nhắn
              </button>
            </form>
          </div>
        </section>
      </>
    </div>
  );
}

export default Home;
