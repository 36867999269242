import React, { useEffect, useRef, useState } from "react";
import "../assets/css/product-detail.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import apiBaseUrl, { formatPrice, getHeader } from "../share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faShieldAlt,
  faShoppingCart,
  faTruck,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import ProductCard from "../components/ProductCard";
import { useCart } from "../components/CartContext";

function ProductDetail() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [similar, setSimilar] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [option, setOption] = useState(0);
  const navigate = useNavigate();
  const { header } = getHeader();
  const { addToCart, checkOutNow, loadingCheckOutNow, confirmError } =
    useCart();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          `${apiBaseUrl}/product/detail/${productId}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const productData = await response.json();

        if (!productData) {
          window.location.href = "/";
          return;
        }
        let data = productData.body;
        data.variants = data.variants.filter((item) => item.stored > 0);
        setProduct(data);
      } catch (error) {
        console.error("Failed to fetch product:", error);
      }
    };

    fetchProduct();
  }, [productId]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          `${apiBaseUrl}/product/detail/${productId}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const productData = await response.json();

        if (!productData) {
          window.location.href = "/";
          return;
        }
        let data = productData.body;
        data.variants = data.variants.filter((item) => item.stored > 0);
        setProduct(data);
      } catch (error) {
        console.error("Failed to fetch product:", error);
      }
    };

    if (confirmError) {
      fetchProduct();
    }
  }, [confirmError]);

  useEffect(() => {
    const fetchSimilarProduct = async () => {
      try {
        const response = await fetch(
          `${apiBaseUrl}/product/similar/${product.category_id}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const productData = await response.json();

        if (!productData) {
          window.location.href = "/";
          return;
        }
        let data = productData.body;
        data = data.filter((item) => item.id != product.id);
        setSimilar(data);
      } catch (error) {
        console.error("Failed to fetch product:", error);
      }
    };
    if (product) fetchSimilarProduct();
  }, [product]);

  useEffect(() => {
    if (product && quantity > product.variants[option].stored) {
      setQuantity(product.variants[option].stored);
    }
  }, [option, product]);

  const addCart = () => {
    const productNew = {
      product_id: product.id,
      product: product,
      size: product.variants[option].size,
      quantity: quantity,
    };

    addToCart(productNew);
  };

  if (!product) {
    return;
  }

  return (
    <>
      <div className="product-detail-container">
        <div className="product-breadcrumb">
          <Link to={"/"}>Trang chủ</Link> /
          <a href="index.html#products">Sản phẩm</a> /
          <span className="product-name">{product.name}</span>
        </div>
        <div className="product-detail">
          <div className="product-gallery">
            <div className="main-image">
              <img
                src={product.thumbnail.path}
                alt=""
                id="main-product-image"
              />
            </div>
            <div className="thumbnail-images">
              {/* Thumbnails will be added here */}
            </div>
          </div>
          <div className="product-info">
            <h1 className="product-title">{product.name}</h1>
            <div className="product-meta">
              <span className="product-sku">
                Số lượng:{" "}
                <span className="sku-value">
                  {product.variants[option].stored}
                </span>
              </span>
              <span className="product-category">
                Danh mục:{" "}
                <span className="category-value">{product.category.name}</span>
              </span>
            </div>
            <div className="product-price">
              {formatPrice(product.variants[option].price)}
            </div>
            <div className="product-description">
              {product.sort_description}
            </div>
            <div className="product-options">
              <div className="size-selection">
                <h3>Kích thước</h3>
                <div className="size-options">
                  {product.variants.map((item, index) => (
                    <button
                      className={`size-option ${
                        index === option ? "active" : ""
                      }`}
                      key={index}
                      onClick={() => setOption(index)}
                    >
                      {item.size.toUpperCase()}
                    </button>
                  ))}
                </div>
              </div>
              <div className="quantity-selection">
                <h3>Số lượng</h3>
                <div className="quantity-input">
                  <button
                    className="quantity-btn minus"
                    onClick={() => {
                      if (quantity > 1) {
                        setQuantity(quantity - 1);
                      }
                    }}
                  >
                    -
                  </button>
                  <input
                    className="appearance-none"
                    value={quantity}
                    onChange={(e) => {
                      const inputQuantity = parseInt(e.target.value, 10);

                      if (!isNaN(inputQuantity)) {
                        if (inputQuantity > product.variants[option].stored) {
                          setQuantity(product.variants[option].stored);
                        } else if (inputQuantity < 1) {
                          setQuantity(1);
                        } else {
                          setQuantity(inputQuantity);
                        }
                      } else {
                        setQuantity(e.target.value);
                      }
                    }}
                    onBlur={() => {
                      if (!quantity) {
                        setQuantity(1);
                      }
                    }}
                    type="number"
                    min={1}
                    max={product.variants[option].stored}
                  />
                  <button
                    className="quantity-btn plus"
                    onClick={() => {
                      if (quantity < product.variants[option].stored) {
                        setQuantity(quantity + 1);
                      }
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div className="product-actions">
              <button className="add-to-cart-btn" onClick={addCart}>
                <FontAwesomeIcon icon={faShoppingCart} />
                Thêm vào giỏ hàng
              </button>
              <button
                className="buy-now-btn"
                onClick={() => checkOutNow(product, quantity, option)}
                disabled={loadingCheckOutNow}
              >
                {loadingCheckOutNow ? (
                  <div
                    role="status"
                    className="flex justify-center items-center"
                  >
                    <svg
                      aria-hidden="true"
                      className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                ) : (
                  "Mua ngay"
                )}
              </button>
            </div>
            <div className="product-features">
              <div className="feature">
                <FontAwesomeIcon icon={faTruck} />
                <span>Miễn phí vận chuyển cho đơn hàng từ 500K</span>
              </div>
              <div className="feature">
                <FontAwesomeIcon icon={faUndo} className="fas" />
                <span>Đổi trả miễn phí trong 30 ngày</span>
              </div>
              <div className="feature">
                <FontAwesomeIcon
                  icon={faShieldAlt}
                  className="fas fa-shield-alt"
                />
                <span>Bảo hành chính hãng 12 tháng</span>
              </div>
            </div>
          </div>
        </div>
        {/* Product Details Tabs */}
        <div className="product-tabs">
          <div className="tab-buttons">
            <button className="tab-btn active" data-tab="description">
              Mô tả
            </button>
            <button className="tab-btn" data-tab="specifications">
              Thông số
            </button>
            <button className="tab-btn" data-tab="size-guide">
              Hướng dẫn chọn size
            </button>
          </div>
          <div className="tab-content">
            <div id="description" className="tab-pane active">
              <div className="product-description-content">
                <h3>Mô tả chi tiết</h3>
                <div className="description-text">
                  <p>
                    Áo sơ mi trắng nam được thiết kế với form regular fit thanh
                    lịch, phù hợp với nhiều vóc dáng. Chất liệu cotton cao cấp
                    mang lại cảm giác thoải mái, thấm hút mồ hôi tốt khi mặc.
                  </p>
                  <h4>Đặc điểm nổi bật</h4>
                  <ul>
                    <li>Chất liệu cotton cao cấp, thoáng mát</li>
                    <li>Form regular fit thanh lịch</li>
                    <li>Đường may tỉ mỉ, chắc chắn</li>
                    <li>Dễ dàng phối đồ</li>
                  </ul>
                  <h4>Hướng dẫn bảo quản</h4>
                  <ul>
                    <li>Giặt máy ở nhiệt độ thường</li>
                    <li>Không sử dụng chất tẩy</li>
                    <li>Ủi ở nhiệt độ thấp</li>
                    <li>Phơi trong bóng râm</li>
                  </ul>
                </div>
              </div>
            </div>
            <div id="specifications" className="tab-pane">
              <div className="specifications-content">
                <h3>Thông số sản phẩm</h3>
                <table className="specs-table">
                  <tbody>
                    <tr>
                      <td>Chất liệu</td>
                      <td>100% cotton</td>
                    </tr>
                    <tr>
                      <td>Xuất xứ</td>
                      <td>Việt Nam</td>
                    </tr>
                    <tr>
                      <td>Kiểu dáng</td>
                      <td>Regular fit</td>
                    </tr>
                    <tr>
                      <td>Cổ áo</td>
                      <td>Cổ đức</td>
                    </tr>
                    <tr>
                      <td>Tay áo</td>
                      <td>Dài</td>
                    </tr>
                    <tr>
                      <td>Họa tiết</td>
                      <td>Trơn</td>
                    </tr>
                    <tr>
                      <td>Phù hợp</td>
                      <td>Công sở, Dự tiệc, Đi chơi</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div id="size-guide" className="tab-pane">
              <div className="size-guide-content">
                <h3>Hướng dẫn chọn size</h3>
                <div className="measurement-guide">
                  <h4>Cách đo</h4>
                  <div className="measurement-steps">
                    <div className="step">
                      <i className="fas fa-ruler" />
                      <p>
                        <strong>Vai:</strong> Đo ngang từ đầu vai trái đến đầu
                        vai phải
                      </p>
                    </div>
                    <div className="step">
                      <i className="fas fa-ruler" />
                      <p>
                        <strong>Ngực:</strong> Đo vòng ngực tại vị trí rộng nhất
                      </p>
                    </div>
                    <div className="step">
                      <i className="fas fa-ruler" />
                      <p>
                        <strong>Eo:</strong> Đo vòng eo tại vị trí nhỏ nhất
                      </p>
                    </div>
                    <div className="step">
                      <i className="fas fa-ruler" />
                      <p>
                        <strong>Dài:</strong> Đo từ đỉnh vai đến gấu áo
                      </p>
                    </div>
                  </div>
                </div>
                <div className="size-table">
                  <h4>Bảng size chi tiết (cm)</h4>
                  <table>
                    <thead>
                      <tr>
                        <th>Size</th>
                        <th>Vai</th>
                        <th>Ngực</th>
                        <th>Eo</th>
                        <th>Dài</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>S</td>
                        <td>42</td>
                        <td>96</td>
                        <td>88</td>
                        <td>68</td>
                      </tr>
                      <tr>
                        <td>M</td>
                        <td>44</td>
                        <td>100</td>
                        <td>92</td>
                        <td>70</td>
                      </tr>
                      <tr>
                        <td>L</td>
                        <td>46</td>
                        <td>104</td>
                        <td>96</td>
                        <td>72</td>
                      </tr>
                      <tr>
                        <td>XL</td>
                        <td>48</td>
                        <td>108</td>
                        <td>100</td>
                        <td>74</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="size-notes">
                  <h4>Lưu ý khi chọn size</h4>
                  <ul>
                    <li>
                      Số đo trong bảng là số đo cơ thể, không phải số đo quần áo
                    </li>
                    <li>
                      Nếu số đo của bạn nằm giữa hai size, hãy chọn size lớn hơn
                    </li>
                    <li>Các số đo có thể chênh lệch 1-2cm do phương pháp đo</li>
                    <li>
                      Liên hệ với chúng tôi nếu bạn cần tư vấn thêm về size
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Related Products */}
        <div className="related-products">
          <h2>Sản phẩm liên quan</h2>
          <div className="product-grid">
            {similar &&
              similar.map((item, index) => (
                <ProductCard key={index} product={item} />
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDetail;
