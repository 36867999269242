import { useEffect, useState } from "react";
import styles from "../assets/css/checkout.module.css";
import "../assets/css/style.css";
import { formatPrice, getHeader } from "../share";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useCart } from "../components/CartContext";
import usePrevious from "../hooks/usePrevious";

function CheckOut() {
  const { source } = useParams();
  const navigation = useNavigate();
  const location = useLocation();
  const { cartItems, status, logout, loadingCreateOrder, submitOrder } =
    useCart();
  const { user } = getHeader();
  const [itemOrders, setItemOrders] = useState([]);
  const [bankMethod, setBankMethod] = useState(false);
  const [shipCod, setShipCod] = useState(30000);
  const [payment, setPayment] = useState("cod");
  const [email, setEmail] = useState(user ? user.email : "");
  const [name, setName] = useState(user ? user.name : "");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [sumBill, setSumBill] = useState(0);

  // Error states
  const [emailError, setEmailError] = useState([]);
  const [nameError, setNameError] = useState([]);
  const [addressError, setAddressError] = useState([]);
  const [phoneError, setPhoneError] = useState([]);
  const [error, setError] = useState([]);

  //previous value field
  const prevEmail = usePrevious(email);
  const prevPhone = usePrevious(phone);
  const prevName = usePrevious(name);
  const prevAddress = usePrevious(address);

  useEffect(() => {
    if (!location.state || !location.state.allowed) {
      navigation(-1, {
        replace: false,
      });
    }
  }, []);

  useEffect(() => {
    const handleNavigation = (items) => {
      if (items.length === 0) {
        navigation(-1);
      } else {
        setItemOrders(items);
      }
    };

    if (source === "cart") {
      handleNavigation(cartItems);
    } else if (source === "now") {
      const nowItem = JSON.parse(localStorage.getItem("buy")) ?? [];
      handleNavigation(nowItem);
    }
  }, [source, cartItems, navigation]);

  useEffect(() => {
    const totalBill = itemOrders.reduce((sum, item) => {
      return (
        sum +
        item.variants.reduce(
          (variantSum, variant) =>
            variantSum +
            item.product.variants.find(
              (variant1) => variant1.size === variant.size
            ).price *
              variant.quantity,
          0
        )
      );
    }, 0);
    setSumBill(totalBill);
  }, [itemOrders]);

  useEffect(() => {
    // Update shipping cost based on payment method
    if (payment === "cod") {
      setBankMethod(false);
      setShipCod(30000);
    } else {
      setBankMethod(true);
      setShipCod(10000);
    }
  }, [payment]);

  // Name validation
  const validateField = (field, value) => {
    const errors = [];
    if (field === "email") {
      if (!value && prevEmail) errors.push("Email field must not be empty");
      if (/\s/.test(value)) errors.push("Email must not contain whitespace");
      if (value && !/^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(value))
        errors.push("Invalid email format");
      setEmailError(errors);
    }

    if (field === "name") {
      if (!value && prevName) errors.push("Name field must not be empty");
      if (value.startsWith(" "))
        errors.push("Name must not start with a whitespace");
      setNameError(errors);
    }

    if (field === "address") {
      if (!value && prevAddress) errors.push("Address field must not be empty");
      if (value.startsWith(" "))
        errors.push("Address must not start with a whitespace");
      setAddressError(errors);
    }

    if (field === "phone") {
      if (!value.startsWith("0") && value)
        errors.push("Phone number must start with 0");
      if (/\s/.test(value))
        errors.push("Phone number must not contain whitespace");
      if (!/^\d+$/.test(value) && value) {
        errors.push("Phone number must only contain numbers");
      }
      if (value.length !== 10 && prevPhone)
        errors.push("Phone number must be exactly 10 digits");
      setPhoneError(errors);
    }
  };

  const onFocusField = (field, value) => {
    if (field === "email") {
      if (!emailError.includes("Email field must not be empty") && !value)
        setEmailError([...emailError, "Email field must not be empty"]);
    }

    if (field === "name") {
      if (!nameError.includes("Name field must not be empty") && !value)
        setNameError([...nameError, "Name field must not be empty"]);
    }

    if (field === "address") {
      if (!addressError.includes("Address field must not be empty") && !value)
        setAddressError([...addressError, "Address field must not be empty"]);
    }

    if (field === "phone") {
      if (
        !phoneError.includes("Phone number must be exactly 10 digits") &&
        !value
      )
        setPhoneError([
          ...phoneError,
          "Phone number must be exactly 10 digits",
        ]);
    }
  };

  useEffect(() => {
    validateField("name", name);
  }, [name]);

  // Email validation
  useEffect(() => {
    validateField("email", email);
  }, [email]);

  // Address validation
  useEffect(() => {
    validateField("address", address);
  }, [address]);

  // Phone validation
  useEffect(() => {
    validateField("phone", phone);
  }, [phone]);

  const checkOutProduct = async () => {
    const errors = [];
    if (!name || nameError.length > 0) {
      errors.push("Name is empty or name field value is incorrect");
    }
    if (emailError.length > 0) {
      errors.push("The email field value is incorrect");
    }
    if (!phone || phoneError.length > 0) {
      errors.push("Phone is empty or phone field value is incorrect");
    }
    if (!address || addressError.length > 0) {
      errors.push("Address is empty or address field value is incorrect");
    }
    setError(errors);

    if (errors.length == 0) {
      await createOrder();
    }
  };

  const createOrder = async () => {
    let order = {
      name,
      phone,
      address,
      total: sumBill + shipCod,
      payment_method: bankMethod ? 2 : 1,
      variants_order: convertVariants(),
    };
    if (note) {
      order = {
        ...order,
        note,
      };
    }
    if (email) {
      order = {
        ...order,
        email,
      };
    }

    if (user) {
      order = {
        ...order,
        user_id: user.id,
      };
    }
    if (source === "cart") {
      order = {
        ...order,
        form: 1,
      };
    } else if (source === "now") {
      order = {
        ...order,
        form: 2,
      };
    }
    await submitOrder(order);
  };

  const convertVariants = () => {
    let product_need = {};
    itemOrders.forEach((item) => {
      product_need = { ...product_need, [item.product_id]: item.variants };
    });
    return product_need;
  };

  return (
    <div className={styles["checkout-container"]}>
      <div className={styles["checkout-content"]}>
        <div className={styles["checkout-form"]}>
          <h2>Thông tin thanh toán</h2>
          <ul className="text-red-500 my-1 pl-6 list-disc">
            {error.map((error, index) => {
              return <li key={index}>{error}</li>;
            })}
          </ul>
          <form id="checkout-form">
            <CustomerInfo
              name={name}
              setName={setName}
              nameError={nameError}
              phone={phone}
              setPhone={setPhone}
              phoneError={phoneError}
              email={email}
              setEmail={setEmail}
              emailError={emailError}
              address={address}
              setAddress={setAddress}
              addressError={addressError}
              note={note}
              setNote={setNote}
              onFocus={(field, value) => onFocusField(field, value)}
            />
            <PaymentMethod
              payment={payment}
              setPayment={setPayment}
              bankMethod={bankMethod}
            />
            <button
              type="button"
              onClick={checkOutProduct}
              className={styles["cta-button"]}
              disabled={loadingCreateOrder}
            >
              {loadingCreateOrder ? (
                <div role="status" className="flex justify-center items-center">
                  <svg
                    aria-hidden="true"
                    className="inline w-7 h-7 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              ) : (
                "Đặt hàng"
              )}
            </button>
          </form>
        </div>
        <OrderSummary
          shipCod={shipCod}
          itemOrders={itemOrders}
          bill={sumBill}
        />
      </div>
    </div>
  );
}

// Customer information form section
const CustomerInfo = ({
  name,
  setName,
  nameError,
  phone,
  setPhone,
  phoneError,
  email,
  setEmail,
  emailError,
  address,
  setAddress,
  addressError,
  note,
  setNote,
  onFocus
}) => (
  <div className={styles["form-section"]}>
    <h3>Thông tin người nhận</h3>
    <FormGroup
      label="name"
      value={name}
      onChange={(e) => setName(e.target.value)}
      errors={nameError}
      onFocus = {(field, value) => onFocus(field, value)}
    />
    <FormGroup
      label="phone"
      value={phone}
      onChange={(e) => setPhone(e.target.value)}
      errors={phoneError}
      onFocus = {(field, value) => onFocus(field, value)}
    />
    <FormGroup
      label="email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      errors={emailError}
      onFocus = {(field, value) => onFocus(field, value)}
    />
    <FormGroup
      label="address"
      value={address}
      onChange={(e) => setAddress(e.target.value)}
      errors={addressError}
      onFocus = {(field, value) => onFocus(field, value)}
      isTextArea
    />
    <FormGroup
      label="Ghi chú"
      value={note}
      onChange={(e) => setNote(e.target.value)}
      onFocus = {(field, value) => onFocus(field, value)}
      isTextArea
    />
  </div>
);

// Payment method selection
const PaymentMethod = ({ payment, setPayment, bankMethod }) => (
  <div className={styles["form-section"]}>
    <h3>Phương thức thanh toán</h3>
    <div className={styles["payment-methods"]}>
      <label className={styles["payment-method"]}>
        <input
          type="radio"
          name="payment"
          value="cod"
          checked={payment === "cod"}
          onChange={() => setPayment("cod")}
        />
        <span>Thanh toán khi nhận hàng</span>
      </label>
      <label className={styles["payment-method"]}>
        <input
          type="radio"
          name="payment"
          value="bank"
          checked={payment === "bank"}
          onChange={() => setPayment("bank")}
        />
        <span>Chuyển khoản ngân hàng</span>
      </label>
    </div>
    {bankMethod && (
      <div className={styles["bank-info"]}>
        <h4>Thông tin chuyển khoản</h4>
        <p>
          <strong>Ngân hàng:</strong> VietComBank
        </p>
        <p>
          <strong>Số tài khoản:</strong> 1234567890
        </p>
        <p>
          <strong>Chủ tài khoản:</strong> FASHION STORE
        </p>
        <p>
          <strong>Nội dung:</strong> [Mã đơn hàng] - [Họ tên người mua]
        </p>
      </div>
    )}
  </div>
);

// Order summary section
const OrderSummary = ({ shipCod, itemOrders, bill }) => (
  <div className={styles["order-summary"]}>
    <h3>Đơn hàng của bạn</h3>
    <div className={styles["cart-items"]}>
      {itemOrders.map((item, index) => (
        <div key={index}>
          <div className={styles["cart-item"]}>
            <div className={styles["item-image"]}>
              <img src={item.product.thumbnail.path} />
            </div>
            <div className={styles["item-details"]}>
              <div className="flex justify-between items-center mb-3">
                <h4>{item.product.name}</h4>
                <div className={styles["item-total"]}>
                  {formatPrice(
                    item.variants.reduce((sum, variant) => {
                      const variantData = item.product.variants.find(
                        (v) => v.size === variant.size
                      );
                      return (
                        sum +
                        (variantData ? variantData.price * variant.quantity : 0)
                      );
                    }, 0)
                  )}
                </div>
              </div>

              <div className="flex gap-5 flex-col">
                {item.variants.map((item1, index) => (
                  <div key={index}>
                    <div className={styles["item-meta"]}>
                      <span className={styles["item-size"]}>
                        Size: {item1.size.toUpperCase()}
                      </span>
                      <div className="flex justify-between">
                        <span className={styles["item-price"]}>
                          {formatPrice(
                            item.product.variants.find(
                              (variant) => variant.size === item1.size
                            ).price
                          )}
                        </span>
                        <div className={styles["item-quantity"]}>
                          <span>Số lượng: {item1.quantity}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
    <div className={styles["order-total"]}>
      <div className={styles["subtotal"]}>
        <span>Tạm tính:</span>
        <span className={styles["amount"]}>{formatPrice(bill)}</span>
      </div>
      <div className={styles["shipping"]}>
        <span>Phí vận chuyển:</span>
        <span className={styles["amount"]}>{formatPrice(shipCod)}</span>
      </div>
      <div className={styles["total"]}>
        <span>Tổng cộng:</span>
        <span className={styles["amount"]}>{formatPrice(bill + shipCod)}</span>
      </div>
    </div>
  </div>
);

// Generic form group for inputs and textareas
const FormGroup = ({
  label,
  value,
  onChange,
  errors = [],
  isTextArea = false,
  onFocus
}) => (
  <div className={styles["form-group"]}>
    <label>{label}</label>
    {isTextArea ? (
      <textarea value={value} onChange={onChange} />
    ) : (
      <input type="text" value={value} onChange={onChange} onFocus={() => onFocus(label, value)}/>
    )}
    <ul className="text-red-500 my-1 pl-6 list-disc">
      {errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))}
    </ul>
  </div>
);

export default CheckOut;
