const apiBaseUrl = "https://fasion.ntu257.vpsttt.vn/api";

function formatPrice(price) {
  return new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  }).format(price);
}

const getHeader = () => {
  const remember = localStorage.getItem("remember");
  let header = "Bearer ";
  let user = null;

  if (remember === "true") {
    header += localStorage.getItem("token");
    user = JSON.parse(localStorage.getItem("user"));
  } else if (remember === "false") {
    header += sessionStorage.getItem("token");
    user = JSON.parse(sessionStorage.getItem("user"));
  } else header = "";

  return { remember: remember, header: header, user: user };
};

const fetchInfor = async () => {
  let { remember, header } = getHeader();
  if (header) {
    await fetch(`${apiBaseUrl}/client/infor`, {
      headers: {
        Authorization: header,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status >= 400) {
          localStorage.removeItem("token");
          sessionStorage.removeItem("token");
          localStorage.removeItem("user");
          sessionStorage.removeItem("user");
          localStorage.removeItem("remember");
        } else {
          if (remember === "true") {
            sessionStorage.removeItem("user");
            localStorage.setItem("user", JSON.stringify(data.body.user));
          } else {
            localStorage.removeItem("user");
            sessionStorage.setItem("user", JSON.stringify(data.body.user));
          }
        }
      });
  }
};

export { formatPrice, fetchInfor, getHeader };
export default apiBaseUrl;
