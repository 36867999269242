import { createContext, useContext, useEffect, useState } from "react";
import apiBaseUrl, { getHeader } from "../share";
import { useNavigate } from "react-router-dom";

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCheckOut, setLoadingCheckOut] = useState(false);
  const [loadingCheckOutNow, setLoadingCheckOutNow] = useState(false);
  const [loadingCreateOrder, setLoadingCreateOrder] = useState(false);
  const [status, setStatus] = useState({});
  const [confirmError, setConfirmError] = useState(false);
  const { remember, header } = getHeader();
  const navigate = useNavigate();

  const fetchSubmitOrder = async (order) => {
    try {
      const response = await fetch(`${apiBaseUrl}/client/order/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...order }),
      });
      
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const data = await response.json();
  
      if (data.status < 400) {
        setStatus({
          show: true,
          status: "success",
          message: ["Đặt hàng thành công"],
        });
        if (order.form == 2) {
          localStorage.removeItem("buy");
        } else {
          if (header) {
            fetchCart();
          } else {
            localStorage.removeItem("cart");
            setCartItems([]);
            setLoading(false);
          }
        }
        navigate("/");
      } else {
        setConfirmError(true);
        setStatus({
          show: true,
          status: "error",
          message: data.body.message,
        });
        setTimeout(() => {
          setConfirmError(false);
        }, 500);
      }
      setLoadingCreateOrder(false);
    } catch (error) {
      setStatus({
        show: true,
        status: "error",
        message: ["Có vấn đề xảy ra trong quá trình kiểm tra thanh toán"],
      });
    }
  };
  
  const submitOrder = async (order) => {
    setLoadingCreateOrder(true);
    try {
      const response = await fetch(`${apiBaseUrl}/product/check_store`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          products_need_check: order.variants_order,
        }),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const data = await response.json();
  
      if (data.status < 400) {
        await fetchSubmitOrder(order); 
      } else {
        setConfirmError(true);
        setStatus({
          show: true,
          status: "error",
          message: data.body.message,
        });
        setTimeout(() => {
          setConfirmError(false);
        }, 500);
      }
      setLoadingCreateOrder(false);
    } catch (error) {
      setStatus({
        show: true,
        status: "error",
        message: ["Có vấn đề xảy ra trong quá trình kiểm tra thanh toán"],
      });
    }
  };
  

  const checkOut = () => {
    let product_need = {};
    cartItems.forEach((item) => {
      product_need = { ...product_need, [item.product_id]: item.variants };
    });
    setLoadingCheckOut(true);
    fetch(`${apiBaseUrl}/product/check_store`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        products_need_check: product_need,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.status < 400) {
          navigate("/checkout/cart", {
            state: { allowed: true }
        });
        } else {
          setConfirmError(true);
          setStatus({
            show: true,
            status: "error",
            message: data.body.message,
          });
          setTimeout(() => {
            setConfirmError(false);
          }, 500);
        }
        setLoadingCheckOut(false);
      })
      .catch((error) => {
        setStatus({
          show: true,
          status: "error",
          message: ["Có vấn đề xảy ra trong quá trình kiểm tra thanh toán"],
        });
      });
  };

  const checkOutNow = (product, quantity, option) => {
    const buy = [
      {
        product_id: product.id,
        product: product,
        variants: [
          {
            size: product.variants[option].size,
            quantity: quantity,
          },
        ],
      },
    ];
    localStorage.setItem("buy", JSON.stringify(buy));
    setLoadingCheckOutNow(true);
    let product_need = {};
    buy.forEach((item) => {
      product_need = { ...product_need, [item.product_id]: item.variants };
    });
    fetch(`${apiBaseUrl}/product/check_store`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        products_need_check: product_need,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.status < 400) {
          navigate("/checkout/now", {
            state: { allowed: true }
        });
        } else {
          setConfirmError(true);
          setStatus({
            show: true,
            status: "error",
            message: data.body.message,
          });
          setTimeout(() => {
            setConfirmError(false);
          }, 500);
        }
        setLoadingCheckOutNow(false);
      })
      .catch((error) => {
        setStatus({
          show: true,
          status: "error",
          message: ["Có vấn đề xảy ra trong quá trình kiểm tra thanh toán"],
        });
      });
  };

  const logout = () => {
    const keysToKeep = ["cart", "checkout"];
    const localStorageItemsToKeep = {};
    const sessionStorageItemsToKeep = {};

    keysToKeep.forEach((key) => {
      if (localStorage.getItem(key)) {
        localStorageItemsToKeep[key] = localStorage.getItem(key);
      }
    });

    localStorage.clear();
    sessionStorage.clear();

    Object.keys(localStorageItemsToKeep).forEach((key) => {
      localStorage.setItem(key, localStorageItemsToKeep[key]);
    });
    setCartItems(JSON.parse(localStorage.getItem("cart")) ?? []);
  };

  const fetchCart = async () => {
    await fetch(`${apiBaseUrl}/client/cart/list`, {
      headers: {
        Authorization: header,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status < 400) {
          setCartItems(data.body.cart);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (status && status.show) {
      const timeoutId = setTimeout(() => {
        setStatus((prevStatus) => ({
          ...prevStatus,
          show: false,
        }));
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [status]);

  useEffect(() => {
    setLoading(true);
    if (header) {
      fetchCart();
    } else {
      setCartItems(JSON.parse(localStorage.getItem("cart")) ?? []);
      setLoading(false);
    }
  }, []);

  const addToCart = async (product) => {
    if (header) {
      setLoading(true);

      try {
        const response = await fetch(`${apiBaseUrl}/client/cart/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: header,
          },
          body: JSON.stringify({
            product_id: product.product_id,
            size: product.size,
            quantity: product.quantity,
          }),
        });

        const data = await response.json();

        if (data.status < 400) {
          setStatus({
            show: true,
            status: "success",
            message: ["Đã thêm vào giỏ hàng thành công"],
          });
          fetchCart();
        } else {
          setStatus({
            show: true,
            status: "error",
            message: ["Thêm vào giỏ hàng thất bại"],
          });
        }
      } catch (error) {
        setStatus({
          show: true,
          status: "error",
          message: ["Lỗi khi thêm sản phẩm vào giỏ hàng"],
        });
      }
    } else {
      let cart = JSON.parse(localStorage.getItem("cart")) || [];
      let flag = false;

      cart.forEach((item) => {
        if (item.product_id === product.product_id) {
          let i = item.variants.findIndex(
            (variant) => variant.size === product.size
          );
          if (i !== -1) {
            item.variants[i].quantity += product.quantity;
          } else {
            item.variants.push({
              size: product.size,
              quantity: product.quantity,
            });
          }
          flag = true;
        }
      });

      if (!flag) {
        cart.push({
          product_id: product.product_id,
          product: product.product,
          variants: [
            {
              size: product.size,
              quantity: product.quantity,
            },
          ],
        });
      }

      setCartItems(cart);
      localStorage.setItem("cart", JSON.stringify(cart));
      setStatus({
        show: true,
        status: "success",
        message: ["Đã thêm vào giỏ hàng thành công"],
      });
    }
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        loading,
        status,
        logout,
        checkOut,
        loadingCheckOut,
        checkOutNow,
        loadingCheckOutNow,
        confirmError,
        loadingCreateOrder,
        submitOrder,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
