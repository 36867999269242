import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainLayout from "./components/MainLayout";
import Home from "./pages/home";
import ProductDetail from "./pages/detail";
import Auth from "./pages/auth";
import { fetchInfor } from "./share";
import CheckOut from "./pages/checkout";
import "./assets/css/index.css";
import "./assets/css/style.css";

function App() {
  useEffect(() => {
    fetchInfor();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="products/:productId" element={<ProductDetail />} />
          <Route path="checkout/:source" element={<CheckOut />} />
        </Route>
        <Route path="/auth/:tab?" element={<Auth />} />
      </Routes>
    </Router>
  );
}

export default App;
