import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import { CartProvider } from "./CartContext";
import "../assets/css/style.css";

function MainLayout() {
  return (
    <div>
      <CartProvider>
        <Header />
        <main>
          <Outlet />
        </main>
      </CartProvider>
      <Footer />
    </div>
  );
}

export default MainLayout;
