import HeaderNotNav from "../components/HeaderNotNav";
import "../assets/css/auth.css";
import React, { useEffect, useRef, useState } from "react";
import apiBaseUrl, { fetchInfor } from "../share";
import { useNavigate, useParams } from "react-router-dom";
import usePrevious from "../hooks/usePrevious";

const  Auth  = React.memo(() => {
  const [formRegister, setFormRegister] = useState(false);
  const { tab } = useParams();
  const navigation = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  //previous value field
  const prevEmail = usePrevious(email);
  const prevPassword = usePrevious(password);
  const prevName = usePrevious(name);
  const prevRePassword = usePrevious(rePassword);

  // State for errors related to each input field
  const [emailError, setEmailError] = useState([]);
  const [passwordError, setPasswordError] = useState([]);
  const [nameError, setNameError] = useState([]);
  const [rePasswordError, setRePasswordError] = useState([]);
  const [error, setError] = useState([]);

  function setEmpty() {
    setEmail("");
    setPassword("");
    setName("");
    setRePassword("");
    setChecked(false);

    setEmailError([]);
    setPasswordError([]);
    setNameError([]);
    setRePasswordError([]);
    setError([]);
  }

  useEffect(() => {
    let remember = localStorage.getItem("remember");
    let token = "";
    let user = "";
    if (remember === "true") {
      token = localStorage.getItem("token");
      user = localStorage.getItem("user");
    } else {
      token = sessionStorage.getItem("token");
      user = sessionStorage.getItem("user");
    }

    if (user && token) {
      navigation("/");
    }
    if (tab === "register") {
      setFormRegister(true);
    } else {
      setFormRegister(false);
    }
  }, []);

  const validateField = (field, value) => {
    const errors = [];
    if (field === "email") {
      if (!value && prevEmail) errors.push("Email field must not be empty");
      if (/\s/.test(value)) errors.push("Email must not contain whitespace");
      if (value && !/^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(value)) errors.push("Invalid email format");
      setEmailError(errors);
    }

    if (field === "password") {
      if (!value && prevPassword) errors.push("Password field must not be empty");
      if (value.length < 6 && value.length > 0) errors.push("Password length must be at least 6 characters");
      if (/\s/.test(value)) errors.push("Password must not contain whitespace");
      setPasswordError(errors);
    }

    if (field === "name") {
      if (!value && prevName) errors.push("Name field must not be empty");
      if (value.startsWith(" ")) errors.push("Name must not start with a whitespace");
      setNameError(errors);
    }

    if (field === "rePassword") {
      if (!value && prevRePassword) errors.push("Confirm password field must not be empty");
      if (value !== password) errors.push("Passwords do not match");
      setRePasswordError(errors);
    }
  };

  const onFocusField = (field, value) => {
    if (field === "email") {
      if (!emailError.includes("Email field must not be empty") && !value) setEmailError([...emailError, "Email field must not be empty"]);
    }

    if (field === "password") {
      if (!passwordError.includes("Password field must not be empty") && !value) setPasswordError([...password, "Password field must not be empty"]);
    }

    if (field === "name") {
      if (!nameError.includes("Name field must not be empty") && !value) setNameError([...nameError, "Name field must not be empty"]);
    }

    if (field === "rePassword") {
      if (!rePasswordError.includes("Confirm password field must not be empty") && !value) setRePasswordError([...rePasswordError, "Confirm password field must not be empty"]);
    }
  }

  // Field-specific validation on change
  useEffect(() => {
    validateField("email", email);
  }, [email]);

  useEffect(() => {
    validateField("password", password);
  }, [password]);

  useEffect(() => {
    validateField("name", name);
  }, [name]);

  useEffect(() => {
    validateField("rePassword", rePassword);
  }, [rePassword, password]);

  const register = async () => {
    const errors = [];
    if (!name || nameError.length > 0) {
      errors.push("Name is empty or name field value is incorrect");
    }
    if (!password || passwordError.length > 0) {
      errors.push("Password is empty or password field value is incorrect");
    }
    if (!rePassword || rePasswordError.length > 0) {
      errors.push("Confirm password is empty or confirm password field value is incorrect");
    }
    if (!email || emailError.length > 0) {
      errors.push("Email is empty or the email field value is incorrect");
    }
    if (!checked) {
      errors.push("You must agree to the terms.");
    }
    setError(errors);
    if (errors.length == 0) {
      try {
        setLoading(true);
        const response = await fetch(`${apiBaseUrl}/client/register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password,
            name: name,
          }),
        });

        const data = await response.json();

        if (data.status >= 400) {
          setError([data.body.message]);
        } else {
          localStorage.setItem("remember", false);
          localStorage.removeItem("token");
          sessionStorage.setItem("token", data.body.token);
          await fetchInfor();
          navigation(-1);
        }
      } catch (error) {
        console.error("Error during registration:", error);
        setError(["Something went wrong. Please try again."]);
      } finally {
        setLoading(false);
      }
    }
  };


  const login = async () => {
    setError([]);
    const errors = [];

    if (!password || passwordError.length > 0) {
      errors.push("Password is empty or password field value is incorrect");
    }
    if (!email || emailError.length > 0) {
      errors.push("Email is empty or the email field value is incorrect");
    }

    setError(errors);

    if (errors.length === 0) {
      try {
        setLoading(true);

        const response = await fetch(`${apiBaseUrl}/client/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        });

        const data = await response.json();

        if (data.status >= 400) {
          setError([data.body.message]);
        } else {
          localStorage.setItem("remember", checked);

          if (checked) {
            sessionStorage.removeItem("token");
            localStorage.setItem("token", data.body.token);
          } else {
            localStorage.removeItem("token");
            sessionStorage.setItem("token", data.body.token);
          }

          await fetchInfor();
          navigation(-1);
        }
      } catch (error) {
        setError(["Login failed. Please try again."]);
      } finally {
        setLoading(false); // Đảm bảo setLoading(false) luôn được gọi
      }
    }
  };

  return (
    <>
      <HeaderNotNav />
      <div className="auth-container">
        <div className="auth-box">
          <div className="auth-tabs">
            <button
              disabled={loading}
              className={`auth-tab ${formRegister ? "" : "active"} `}
              onClick={() => {
                setEmpty();
                setFormRegister(false);
              }}
            >
              Đăng nhập
            </button>
            <button
              disabled={loading}
              className={`auth-tab ${formRegister ? "active" : ""} `}
              onClick={() => {
                setEmpty();
                setFormRegister(true);
              }}
            >
              Đăng ký
            </button>
          </div>
          {/* Form đăng nhập */}
          <div
            id="login-form"
            className={`auth-form ${formRegister ? "" : "active"} `}
          >
            <ul className="text-red-500 my-1 pl-6 list-disc">
              {error.map((error, index) => {
                return <li key={index}>{error}</li>;
              })}
            </ul>
            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onFocus={() => onFocusField('email', email)}
              />
              <ul className="text-red-500 my-1 pl-6 list-disc">
                {emailError.map((error, index) => {
                  return <li key={index}>{error}</li>;
                })}
              </ul>
            </div>
            <div className="form-group">
              <label>Mật khẩu</label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() =>onFocusField('password', password)}
              />
              <ul className="text-red-500 my-1 pl-6 list-disc">
                {passwordError.map((error, index) => {
                  return <li key={index}>{error}</li>;
                })}
              </ul>
            </div>
            <div className="form-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  name="remember"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <span className="ml-2">Ghi nhớ đăng nhập</span>
              </label>
              <a href="#" className="forgot-password">
                Quên mật khẩu?
              </a>
            </div>
            <button
              disabled={loading}
              type="submit"
              className="auth-button"
              onClick={login}
            >
              {loading ? (
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              ) : (
                "Đăng nhập"
              )}
            </button>
          </div>
          {/* Form đăng ký */}
          <div
            id="register-form"
            className={`auth-form ${formRegister ? "active" : ""} `}
          >
            <ul className="text-red-500 my-1 pl-6 list-disc">
              {error.map((error, index) => {
                return <li key={index}>{error}</li>;
              })}
            </ul>
            <div className="form-group">
              <label>Họ và tên</label>
              <input
                type="text"
                name="fullName"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onFocus={() => onFocusField('name', name)}
              />
              <ul className="text-red-500 my-1 pl-6 list-disc">
                {nameError.map((error, index) => {
                  return <li key={index}>{error}</li>;
                })}
              </ul>
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onFocus={() => onFocusField('email', email)}
              />
              <ul className="text-red-500 my-1 pl-6 list-disc">
                {emailError.map((error, index) => {
                  return <li key={index}>{error}</li>;
                })}
              </ul>
            </div>
            <div className="form-group">
              <label>Mật khẩu</label>
              <input
                type="password"
                name="password"
                required=""
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => onFocusField('password', password)}
              />
              <ul className="text-red-500 my-1 pl-6 list-disc">
                {passwordError.map((error, index) => {
                  return <li key={index}>{error}</li>;
                })}
              </ul>
            </div>
            <div className="form-group">
              <label>Nhập lại mật khẩu</label>
              <input
                type="password"
                name="confirmPassword"
                required=""
                value={rePassword}
                onChange={(e) => setRePassword(e.target.value)}
                onFocus={() => onFocusField('rePassword', rePassword)}
              />
              <ul className="text-red-500 my-1 pl-6 list-disc">
                {rePasswordError.map((error, index) => {
                  return <li key={index}>{error}</li>;
                })}
              </ul>
            </div>
            <div className="form-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  name="terms"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <span className="ml-2">
                  Tôi đồng ý với các điều khoản sử dụng
                </span>
              </label>
            </div>
            <button type="submit" className="auth-button" onClick={register}>
              {loading ? (
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              ) : (
                "Đăng kí"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
})

export default Auth;
